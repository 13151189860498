import React from 'react'
import { Layout, Seo } from '@global'
import { Container, Button, Gradient, Title } from '@UI'
import { Fade } from '@animations'

const SuccessPage = () => {
  return (
    <Layout>
      <Seo title='Thank You!' />
      <Container
        section
        isSection
        style={{
          position: 'relative',
          minHeight: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Gradient />

        <Fade>
          <Container wrapperSm textBlock textCenter>
            <Title>Email Submitted</Title>
            <p>
              Thank you for contacting us. We will reply as soon as possible.
            </p>
            <Button to='/'>Return Home</Button>
          </Container>
        </Fade>
      </Container>
    </Layout>
  )
}

export default SuccessPage
